import { useMutation, useQuery, useQueryClient } from 'react-query'
import { addToCart, getCart, removeFromCart } from '../services/cartService'
import toast from 'react-hot-toast'
import { useParent } from '../contexts/ParentContext'

export const useCartQuery = (parentId, options = {}) => {
  return useQuery(['carts', parentId], () => getCart(parentId), options)
}

export const useAddTocartMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addToCart,
    // When mutate is called:
    onMutate: async ({ parentId, payload: cartItem }) => {
      const toastId = toast.loading('Adding To Cart...')
      // Check if Optimistic data
      cartItem.isDummy = true
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['carts', parentId] })
      // Snapshot the previous value
      const previousCartItems = queryClient.getQueryData(['carts', parentId]) || []
      console.log(previousCartItems)
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['carts', parentId],
        [...previousCartItems, { ...cartItem, id: Date.now() }],
      )
      // Return a context object with the snapshotted value
      return { previousCartItems, toastId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { parentId }, context) => {
      queryClient.setQueryData(['carts', parentId], context.previousCartItems)
    },
    // Always refetch after error or success:
    onSettled: (_, error, { parentId }, context) => {
      if (error) {
        toast.error(error || 'Failed while adding to cart', { id: context.toastId })
      } else {
        toast.success('Added to Cart', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['carts', parentId] })
    },
  })
}

export const useRemoveFromCartMutation = () => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: removeFromCart,
    // When mutate is called:
    onMutate: async (cartItemId) => {
      const toastId = toast.loading('Removing From Cart...')
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['carts', parentId] })
      // Snapshot the previous value
      const previousCartItems = queryClient.getQueryData(['carts', parentId]) || []
      // Return a context object with the snapshotted value
      queryClient.setQueryData(
        ['carts', parentId],
        previousCartItems.filter((cartItem) => cartItem.id !== cartItemId),
      )
      // Return a context object with the snapshotted value
      return { previousCartItems, toastId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, result, context) => {
      queryClient.setQueryData(['carts', parentId], context.previousCartItems)
    },
    // Always refetch after error or success:
    onSettled: (cartItemId, error, variables, context) => {
      if (error) {
        toast.error(error || 'Faild To Removed From Cart', { id: context.toastId })
      } else {
        toast.success('Removed From Cart', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['carts', parentId] })
    },
  })
}
