/* eslint-disable max-lines */
import { factory, primaryKey } from '@mswjs/data'
import { IMAGES } from '../../assets/images/images'

const db = factory({
  users: {
    id: primaryKey(Number),
    type: String,
    email: String,
    password: String,
    name: String,
    role: String,
    accessToken: String,
  },
  childAccounts: {
    id: primaryKey(Number),
    customerAccountName: String,
  },
  styles: {
    id: primaryKey(Number),
    styleName: String,
    subStyleName: String,
  },
  templates: {
    id: primaryKey(Number),
    style: String,
    frontThumbnail: String,
    design: String,
  },
  templateColors: {
    id: primaryKey(Number),
    style: String,
    frontThumbnail: String,
    design: String,
  },
  templatePrices: {
    id: primaryKey(Number),
    dozenValue: Number,
    pricePerUnit: Number,
  },
  templateSizeScales: {
    id: primaryKey(Number),
    userId: Number,
    name: String,
    xs: Number,
    s: Number,
    m: Number,
    l: Number,
    x: Number,
    xl: Number,
    xxl: Number,
  },
})

db.users.create({
  id: 1,
  type: 'NEW_USER',
  email: 'ava@gmail.com',
  password: '1234567890',
  name: 'Ava',
  role: 'CP',
  accessToken: 'xxx',
})
db.users.create({
  id: 2,
  type: 'VERIFIED_USER',
  email: 'evans@gmail.com',
  password: '1234567890',
  name: 'Evans',
  role: 'SP',
  accessToken: 'xxx',
})
// db.templateSizeScales.create({
//   id: 1,
//   userId: 1,
//   name: 'My Scale',
//   xs: 2,
//   s: 2,
//   m: 2,
//   l: 2,
//   xl: 2,
//   xxl: 2,
// })

Array.from({ length: 7 }).forEach((_, i) => {
  ++i
  db.childAccounts.create({ id: i, customerAccountName: `Location ${i}` })
  db.styles.create({
    id: i,
    styleName: i % 2 === 0 ? 'Tees' : 'Sweats',
    subStyleName: `Sub Style ${i}`,
  })
  db.templates.create({
    id: i,
    style: i % 2 === 0 ? 'Tees' : 'Sweats',
    subStyle: `Sub Style ${i}`,
    frontThumbnail: IMAGES.CardImg1,
    design: 'front',
  })
})
db.templatePrices.create({
  id: 1,
  dozenValue: 0,
  pricePerUnit: 20,
})
db.templatePrices.create({
  id: 2,
  dozenValue: 6,
  pricePerUnit: 19,
})
db.templatePrices.create({
  id: 3,
  dozenValue: 10,
  pricePerUnit: 18,
})
db.templateColors.create({
  id: 1,
  colors: [
    {
      id: 184,
      name: 'indigo - 7546 c',
      actual_name: 'Indigo - 7546 C',
      color: 'CMYKColor',
      cyan: '74.18172955513',
      black: '41.5503144264221',
      yellow: '51.1314511299133',
      magenta: '66.0456240177155',
      swatchGroupIndex: '3',
      swatchIndex: '0',
      shade: 'lt',
      category: 'graphic',
      design: 'front',
      templateId: 25,
      createdAt: '2022-11-28T12:07:41.000Z',
      updatedAt: '2022-11-28T12:07:41.000Z',
      deletedAt: null,
    },
  ],
  layers: [
    {
      id: 8,
      name: 'text',
      length: '12',
      contents: 'Nantucket',
      type: 'text',
      connected: false,
      templateId: 25,
      createdAt: '2022-11-28T12:07:41.000Z',
      updatedAt: '2022-11-28T12:07:41.000Z',
      deletedAt: null,
    },
  ],
})

export default db
