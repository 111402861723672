import * as React from 'react'
import { ELEMENTOR_URL } from '../../config'

const RedirectToElementorSite = () => {
  React.useEffect(() => {
    window.location.href = ELEMENTOR_URL
  }, [])
  return null
}

export default RedirectToElementorSite
