import { createContext, useContext, useEffect, useReducer } from 'react'
import { useQueryClient } from 'react-query'
import {
  useMutationChangePassword,
  useMutationLogin,
  useMutationFetchProfileMe,
} from '../react-query/users'
import useChildStore from '../store/useChildStore'
import useStyleStore from '../store/useStyleStore'
import useParentStore from '../store/useParentStore'

const initialState = {
  isLoggingIn: false,
  isRessettingPassword: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const authContext = createContext({
  isLoggingIn: false,
  loginError: null,
  isRessettingPassword: false,
  resetPasswordError: null,
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  resetPassword: () => {},
  login: () => {},
  logout: () => {},
  initialize: () => {},
})

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload
    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
}

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const [state, dispatch] = useReducer(reducer, initialState)
  const fetchChilds = useChildStore((state) => state.fetch)
  const fetchStyles = useStyleStore((state) => state.fetch)
  const fetchParents = useParentStore((state) => state.fetch)
  const handleError = (err) => {
    console.log(err)
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        user: null,
      },
    })
  }
  const handleSuccess = (user) => {
    fetchParents()
    fetchChilds()
    fetchStyles()
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user,
      },
    })
  }

  const { mutate: fetchProfile } = useMutationFetchProfileMe({
    onError: handleError,
    onSuccess: handleSuccess,
  })

  const {
    mutate: resetPassword,
    isLoading: isRessettingPassword,
    error: resetPasswordError,
  } = useMutationChangePassword({
    onError: (err) => {
      handleError(err)
    },
    onSuccess: (user) => {
      window.localStorage.setItem('accessToken', user.accessToken)
      window.localStorage.setItem('refreshToken', user.refreshToken)
      handleSuccess(user)
    },
  })

  const {
    mutate: login,
    isLoading: isLoggingIn,
    error: loginError,
  } = useMutationLogin({
    onError: (err) => {
      handleError(err)
    },
    onSuccess: (user) => {
      window.localStorage.setItem('accessToken', user.accessToken)
      window.localStorage.setItem('refreshToken', user.refreshToken)
      handleSuccess(user)
    },
  })

  const initialize = () => {
    const accessToken = window.localStorage.getItem('accessToken')
    try {
      if (!accessToken) throw new Error('No accessToken')
      fetchProfile()
    } catch (err) {
      handleError(err?.message || 'Something Wen Wrong')
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  const logout = async () => {
    await queryClient.invalidateQueries()
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
    dispatch({ type: 'LOGOUT' })
  }

  return (
    <authContext.Provider
      value={{
        ...state,
        isLoggingIn,
        loginError,
        isRessettingPassword,
        resetPasswordError,
        resetPassword,
        login,
        logout,
        initialize,
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export const useAuth = () => useContext(authContext)
export default AuthProvider
