/* eslint-disable no-unused-vars */
import { rest } from 'msw'
import accounts from '../db/accounts'
import { errorResponse, successResponse } from '../extras/helpers'

const account = [
  rest.get('/api/users/parents', async (req, res, ctx) => {
    const parents = accounts.parents.getAll()
    return successResponse({ res, ctx, data: parents })
  }),
  rest.get('/api/users/customers', async (req, res, ctx) => {
    const parentId = req.url.searchParams.get('parentId')
    const customers = accounts.childs.getAll({ where: parentId })
    return successResponse({ res, ctx, data: customers })
  }),
]

export default account
