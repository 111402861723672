import { Toaster } from 'react-hot-toast'
import Router from './router'
import 'react-phone-number-input/style.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ScrollToTop from './components/ScrollToTop'

const App = () => {
  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      />

      <Router />
      <ScrollToTop />
    </>
  )
}

export default App
