import * as React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const InputError = ({ id, error }) => {
  return (
    <>
      {error && (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={id}>
              <span>{error?.message}</span>
            </Tooltip>
          }
        >
          <i className='fa-solid fa-circle-exclamation'></i>
        </OverlayTrigger>
      )}
    </>
  )
}

export default InputError
