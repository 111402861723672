import React from 'react'
import { Link } from 'react-router-dom'

const TermsConditions = () => {
  return (
    <>
      <div className='terms-box'>
        <div className='container'>
          <div className='terms-wrap'>
            <div className='terms-section'>
              <h3 className='heading26'>Terms of Service</h3>
              <div className='terms-section-para'>
                <p className='heading16'>Last Updated: August 15, 2023</p>
                <p className='heading16'>
                  These Terms of Service (&ldquo;Terms&ldquo;) govern your use of the website
                  &ldquo;standardrecreation.com&ldquo; (&ldquo;the Website&ldquo;) operated by
                  Standard Recreation Operating Company, LLC (&ldquo;we,&ldquo; &ldquo;our,&ldquo;
                  or &ldquo;us&ldquo;). By accessing or using the Website, you agree to comply with
                  these Terms. If you do not agree with these Terms, please refrain from using the
                  Website.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>1. Acceptable Use</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  You agree to use the Website only for lawful purposes and in a manner that does
                  not infringe upon the rights of any third party. You shall not engage in any
                  activity that may cause harm to the Website, impair its functionality, or
                  interfere with the experiences of other users.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>2. User Accounts</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  2.1 You may be required to create a user account to access certain features of the
                  Website. You are responsible for maintaining the confidentiality of your account
                  information and are liable for all activities that occur under your account.
                </p>
                <p className='heading16'>
                  2.2 You agree to provide accurate and complete information when creating an
                  account or using the Website&sbquo;s services. You must promptly update your
                  account information to keep it current and accurate.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>3. Intellectual Property</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  3.1 All content on the Website, including text, graphics, images, logos, and
                  software, is protected by copyright and other intellectual property laws. You may
                  not reproduce, distribute, modify, or create derivative works without our explicit
                  consent.
                </p>
                <p className='heading16'>
                  3.2 You retain ownership of any content you submit to the Website. By submitting
                  content, you grant us a non-exclusive, worldwide, royalty-free license to use,
                  display, and distribute your content for the purposes of operating and promoting
                  the Website.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>4. Privacy</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  Your use of the Website is also governed by our Privacy Policy, which can be found
                  <Link to='https://www.standardrecreation.com/privacy'> here</Link>. By using the
                  Website, you consent to the collection, use, and sharing of your information as
                  described in the Privacy Policy.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>5. Termination</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  We reserve the right to suspend or terminate your access to the Website at our
                  sole discretion, without notice, for any violation of these Terms or for any other
                  reason.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>6. Disclaimer of Warranties</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  The Website is provided &ldquo;as is&ldquo; and &ldquo;as available&ldquo; without
                  any warranties of any kind, either express or implied, including, but not limited
                  to, the implied warranties of merchantability, fitness for a particular purpose,
                  or non-infringement.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>7. Limitation of Liability</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  In no event shall we be liable for any direct, indirect, incidental, special, or
                  consequential damages arising out of or relating to your use of the Website.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>8. Changes to Terms</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  We reserve the right to modify or replace these Terms at any time. The date of the
                  last update will be provided at the beginning of the Terms. Your continued use of
                  the Website after any changes constitutes your acceptance of the revised Terms.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>9. Governing Law</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  These Terms shall be governed by and construed in accordance with the laws of
                  [Your Jurisdiction], without regard to its conflict of law principles.
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>10. Contact Us</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  If you have any questions about these Terms, please contact us at
                  <a href={'mailTo:info@standardrecreation.com'}> info@standardrecreation.com</a>.
                </p>
              </div>
            </div>
          </div>
          <div className='terms-section'>
            <div className='terms-section-para'>
              <p className='heading16'>
                Please replace &ldquo;[Your Jurisdiction]&ldquo; and &ldquo;[Contact Email]&ldquo;
                with the appropriate information specific to your business and location.
                Additionally, make sure to create a comprehensive Privacy Policy that outlines how
                user data is collected, used, and protected on your website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsConditions
