import { rest } from 'msw'
import db from '../db'
import { successResponse } from '../extras/helpers'

const childAccount = [
  rest.get('/api/childAccount/list', (req, res, ctx) => {
    const data = db.childAccounts.getAll()
    return successResponse({ res, ctx, data })
  }),
]
export default childAccount
