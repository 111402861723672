import { QueryClient } from 'react-query'
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false,
    },
  },
})
export * as UserQueries from './users'
