import create from 'zustand'
import { fetchChildAccounts } from '../services/childAccountService'

const useChildStore = create((set) => ({
  childAccounts: [],
  childAccountMap: {},
  fetch: async () => {
    const childAccounts = await fetchChildAccounts()
    set({
      childAccounts: childAccounts.map(({ id, parentId, customerAccountName }) => ({
        id,
        parentId,
        customerAccountName,
      })),
      childAccountMap: childAccounts.reduce((a, { id, parentId, customerAccountName }) => {
        a[id] = {
          id,
          parentId,
          customerAccountName,
        }
        return a
      }, {}),
    })
  },
}))

export default useChildStore
