import Footer from './Footer/Footer'
import Header from './Header/Header'
import { Outlet } from 'react-router-dom'
// import Chat from '../../components/Chat/Chat'
const MainLayout = () => {
  return (
    <div className='wrapper'>
      <Header />
      <Outlet />
      {/* <Chat /> */}
      <Footer />
    </div>
  )
}

export default MainLayout
