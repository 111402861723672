import create from 'zustand'
import { getParents } from '../services/accountService'

const useParentStore = create((set) => ({
  parents: [],
  parentMap: {},
  fetch: async () => {
    const _parents = await getParents()
    const parents = _parents.sort((a, b) => a.accountName.localeCompare(b.accountName))
    const parentMap = parents.reduce((a, c) => {
      a[c.id] = c
      return a
    }, {})
    set({ parents, parentMap })
  },
}))

export default useParentStore
