import * as Yup from 'yup'
import * as React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import styles from './styles.module.scss'
import { IMAGES } from '../../assets/images/images'
import InputError from '../../sections/auth/InputError'
import { userService } from '../../services'
import { AUTH_PATH } from '../../router/paths'

const schema = Yup.object({
  token: Yup.string().required('Token is required'),
  password: Yup.string().required('Password is required.').min(8, 'Must have minimum 8 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is Required')
    .equals([Yup.ref('password')], 'Password and Confirm Password should be same'),
})

const ResetPassword = () => {
  const paths = window.location.pathname.split('/').filter((path) => path !== '')
  const initialValues = {
    token: paths[paths.length - 1],
    password: '',
    confirmPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const {
    register,
    formState: { errors, isSubmitting },
    reset,
  } = methods

  const onSubmit = async (values) => {
    try {
      await userService.resetPassword(values)
      reset(initialValues)
      toast.success('Your password is updated successfully.', {
        duration: '1000',
        position: 'top-center',
      })
    } catch (err) {
      console.error(err)
      toast.error(err, {
        duration: '1000',
        position: 'top-center',
      })
    }
  }

  return (
    <div className={styles.outerWrap}>
      <div className={styles.formInner}>
        <div className={styles.logoWrap}>
          <img src={IMAGES.Logo} className={styles.logo} />
        </div>
        <div className={styles.innerWrap}>
          <p className={styles.formSubTitle}>Enter your new password & confirm password</p>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={styles.formWrap}>
              <fieldset disabled={isSubmitting}>
                <div
                  className={cn({ [styles.formControl]: true, [styles.error]: errors.password })}
                >
                  <input type='password' {...register('password')} placeholder='Enter password' />
                  <InputError id='password-help' error={errors.password} />
                </div>
                <div
                  className={cn({
                    [styles.formControl]: true,
                    [styles.error]: errors.confirmPassword,
                    [styles.password]: !errors.confirmPassword,
                  })}
                >
                  <input
                    type='password'
                    {...register('confirmPassword')}
                    placeholder='Enter confirm password'
                  />
                  <InputError id='confirm-password-help' error={errors.confirmPassword} />
                </div>
                <div className={styles.btnWrapper}>
                  <button className={styles.submitBtn}>
                    {isSubmitting ? <Spinner size='sm' /> : 'Submit'}
                  </button>
                  <Link to={AUTH_PATH.login}>
                    <button className={styles.link}>Return to login</button>
                  </Link>
                </div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
