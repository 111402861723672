const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const fDate = (date) => {
  const _date = new Date(date)
  return `${months[_date.getMonth()]} ${_date.getDate()}, ${_date.getFullYear()}`
}

export const formatMoney = (amount, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers,
    // but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  return formatter.format(Number(amount)).replace('$', '')
}

export const genTrakingLink = (id) => {
  return `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${id}`
}
