import { rest } from 'msw'
import db from '../db'
import { successResponse } from '../extras/helpers'

const templates = [
  rest.get('/api/childAccount/:childId/templates', (req, res, ctx) => {
    const data = db.templates.getAll()
    return successResponse({ res, ctx, data })
  }),

  rest.get('/api/templates/template-by-id/:templateId', (req, res, ctx) => {
    const data = db.templates.findFirst({
      where: { id: { equals: parseInt(req.params.templateId) } },
    })
    return successResponse({ res, ctx, data })
  }),

  rest.get('/api/templateColor/:templateId', (req, res, ctx) => {
    const data = db.templateColors.findFirst()
    return successResponse({ res, ctx, data })
  }),
  rest.get('/api/templatePrice/templateId/:templateId', (req, res, ctx) => {
    const data = db.templatePrices.getAll()
    return successResponse({ res, ctx, data })
  }),
  rest.get('/api/custom-size/list', (req, res, ctx) => {
    const data = db.templateSizeScales.getAll()
    return successResponse({ res, ctx, data })
  }),
  rest.post('/api/custom-size/save', (req, res, ctx) => {
    const data = db.templateSizeScales.create({ id: Date.now(), ...req.body })
    return successResponse({ res, ctx, data })
  }),
  rest.get('/api/custom-size/:id', (req, res, ctx) => {
    const data = db.templateSizeScales.findFirst({ where: { id: { equals: req.params.id } } })
    return successResponse({ res, ctx, data })
  }),
  rest.put('/api/custom-size/:id', (req, res, ctx) => {
    db.templateSizeScales.update({
      where: { id: { equals: parseInt(req.params.id) } },
      data: req.body,
    })
    return successResponse({ res, ctx, data: req.body })
  }),
  rest.delete('/api/custom-size/:id', (req, res, ctx) => {
    const data = db.templateSizeScales.delete({
      where: { id: { equals: parseInt(req.params.id) } },
    })
    return successResponse({ res, ctx, data })
  }),
]
export default templates
