/* eslint-disable max-len */
import { toast } from 'react-hot-toast'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParent } from '../contexts/ParentContext'
import {
  getShippingAddresses,
  getShippingAddress,
  updateShippingAddress,
  deleteShippingAddress,
  addShippingAddress,
  getOrders,
  assignRemoveChildAccounts,
  getOrderById,
  getBillingAddress,
  updateBillingAddress,
  getSalesUseTaxForms,
} from '../services/accountService'
import { addSalesUseTaxForm, deleteSalesUseTaxForm } from '../services/salesUseTaxService'

export const useShippingAddressesQuery = ({ parentId }, options = {}) => {
  return useQuery(
    ['shipping-addresses', parentId],
    () => getShippingAddresses({ parentId }),
    options,
  )
}
export const useSalesUseTaxQuery = ({ parentId }, options = {}) => {
  return useQuery(
    ['sales-use-tax-forms', parentId],
    () => getSalesUseTaxForms({ parentId }),
    options,
  )
}

export const useShippingAddressQuery = (shippingAddressId) => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useQuery({
    queryKey: ['shipping-address', shippingAddressId],
    queryFn: () => getShippingAddress(shippingAddressId),
    initialData: () => {
      const addresses = queryClient.getQueryData(['shipping-addresses', parentId])
      return addresses?.find((address) => address.id === shippingAddressId)
    },
    enabled: Boolean(shippingAddressId),
  })
}

export const useAddShippingAddressMutation = () => {
  const { parentId } = useParent()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addShippingAddress,
    // When mutate is called:
    onMutate: async (address) => {
      const toastId = toast.loading('Adding Shipping Address...')
      // Check if Optimistic data
      address.isDummy = true
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['shipping-addresses', address.parentId] })
      // Snapshot the previous value
      const previousAddresses =
        queryClient.getQueryData(['shipping-addresses', address.parentId]) || []
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['shipping-addresses', address.parentId],
        [...previousAddresses, { id: Date.now(), ...address }],
      )
      // Return a context object with the snapshotted value
      return { previousAddresses, toastId, parentId: address.parentId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, address, context) => {
      queryClient.setQueryData(['shipping-addresses', context.parentId], context.previousAddresses)
    },
    // Always refetch after error or success:
    onSettled: (address, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Add Shipping Address', { id: context.toastId })
      } else {
        toast.success('Shipping Address Added', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['shipping-addresses', parentId] })
    },
  })
}


export const useAddSalesUseTaxFormMutation = () => {
  const { parentId } = useParent()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addSalesUseTaxForm,
    // When mutate is called:
    onMutate: async (salesTaxForm) => {
      const toastId = toast.loading('Adding Sales and Use Tax Form...')
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['sales-use-tax-forms', salesTaxForm.parentId] })
      // Snapshot the previous value
      const previousSalesTaxForm =
        queryClient.getQueryData(['sales-use-tax-forms', salesTaxForm.parentId]) || []
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['sales-use-tax-forms', salesTaxForm.parentId],
        [...previousSalesTaxForm, { id: Date.now(), ...salesTaxForm }],
      )
      // Return a context object with the snapshotted value
      return { previousSalesTaxForm, toastId, parentId: salesTaxForm.parentId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, salesTaxForm, context) => {
      queryClient.setQueryData(['sales-use-tax-forms', context.parentId], context.previousSalesTaxForm)
    },
    // Always refetch after error or success:
    onSettled: (salesTaxForm, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Add Sales and Use Tax Form', { id: context.toastId })
      } else {
        toast.success('Sales and Use Tax Form Added', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['sales-use-tax-forms', parentId] })
    },
  })
}

export const useUpdateShippingAddressMutation = () => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: updateShippingAddress,
    // When mutate is called:
    onMutate: async ({ shippingAddressId, payload }) => {
      const toastId = toast.loading('Updating Shipping Address...')
      // Check if Optimistic data
      payload.isDummy = true
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['shipping-addresses', parentId] })
      // Snapshot the previous value
      const previousAddresses = queryClient.getQueryData(['shipping-addresses', parentId])
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['shipping-addresses', parentId],
        previousAddresses.map((item) => (item.id === shippingAddressId ? payload : item)),
      )
      // Return a context object with the snapshotted value
      return { previousAddresses, toastId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, address, context) => {
      queryClient.setQueryData(['shipping-addresses', parentId], context.previousAddresses)
    },
    // Always refetch after error or success:
    onSettled: (address, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Update Shipping Address', { id: context.toastId })
      } else {
        toast.success('Shipping Address Updated', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['shipping-addresses', parentId] })
    },
  })
}

export const useDeleteShippingAddressMutation = () => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: deleteShippingAddress,
    // When mutate is called:
    onMutate: async (shippingAddressId) => {
      const toastId = toast.loading('Deleting Shipping Address...')
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['shipping-addresses', parentId] })
      // Snapshot the previous value
      const previousAddresses = queryClient.getQueryData(['shipping-addresses', parentId])
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['shipping-addresses', parentId],
        previousAddresses.filter((item) => item.id !== shippingAddressId),
      )
      // Return a context object with the snapshotted value
      return { previousAddresses, toastId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, address, context) => {
      queryClient.setQueryData(['shipping-addresses', parentId], context.previousAddresses)
    },
    // Always refetch after error or success:
    onSettled: (address, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Delete Shipping Address', { id: context.toastId })
      } else {
        toast.success('Shipping Address Deleted', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['shipping-addresses', parentId] })
    },
  })
}
export const useDeleteSaleandTaxFormMutation = () => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: deleteSalesUseTaxForm,
    // When mutate is called:
    onMutate: async (id) => {
      const toastId = toast.loading('Deleting Sales use tax form...')
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['sales-use-tax-forms', parentId] })
      // Snapshot the previous value
      const previousSalesTaxForm = queryClient.getQueryData(['sales-use-tax-forms', parentId])
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['sales-use-tax-forms', parentId],
        previousSalesTaxForm.filter((item) => item.id !== id),
      )
      // Return a context object with the snapshotted value
      return { previousSalesTaxForm, toastId }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, salesTaxForm, context) => {
      queryClient.setQueryData(['sales-use-tax-forms', parentId], context.previousSalesTaxForm)
    },
    // Always refetch after error or success:
    onSettled: (salesTaxForm, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Delete Sales use tax form', { id: context.toastId })
      } else {
        toast.success('Sales use tax form Deleted', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['sales-use-tax-forms', parentId] })
    },
  })
}

export const useOrdersQuery = (query, options = {}) => {
  return useQuery(['orders', query], () => getOrders(query), options)
}

export const useOrderQuery = (orderId, query = {}, options = {}) => {
  return useQuery(['orders', orderId], () => getOrderById(orderId, query), options)
}

export const useAssignRemoveChildAccountMutation = () => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: assignRemoveChildAccounts,
    onMutate: async () => {
      const toastId = toast.loading('Saving Buyer Locations...')
      // Return a context object with the snapshotted value
      return { toastId }
    },
    // Always refetch after error or success:
    onSettled: (address, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Save Buyer Locations', { id: context.toastId })
      } else {
        toast.success('Buyer Locations Saved', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['buyers', parentId] })
    },
  })
}

export const useBillingAddressQuery = ({ parentId }) => {
  return useQuery({
    queryKey: ['billing-address', parentId],
    queryFn: () => getBillingAddress(parentId),
  })
}

export const useBillingAddressMutation = ({ parentId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn:
      updateBillingAddress,
    onMutate: async () => {
      const toastId =
        toast.loading('Saving billing address...')
      // Return a context object with the snapshotted value
      return { toastId }
    },
    // Always refetch after error or success:
    onSettled: (data, error, variables, context) => {
      if (error) {
        toast.error(error || 'Failed To Save Billing address', { id: context.toastId })
      } else {
        toast.success('Billing address saved successfully.', { id: context.toastId })
      }
      queryClient.invalidateQueries({ queryKey: ['billing-address', parentId] })
    },
  })
}