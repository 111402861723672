import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { ACCOUNT_PATH } from '../router/paths' 
import LoadingScreen from '../components/LoaderScreen/LoadingScreen'

const GuestGuard = ({ children }) => {
  const { isInitialized, isAuthenticated } = useAuth()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (isAuthenticated) {
    return <Navigate to={ACCOUNT_PATH.root} />
  }
  return <>{children}</>
}

export default GuestGuard
