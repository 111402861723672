import create from 'zustand'
import { fetchStyles } from '../services/styleService'

const useStyleStore = create((set, get) => ({
  styles: [],
  Tees: [],
  Sweats: [],
  getStyles: (type) => {
    let _styles
    if (type === 'Tees') _styles = get().Tees
    else if (type === 'Sweats') _styles = get().Sweats
    else _styles = get().styles
    return _styles
  },
  fetch: async () => {
    const styles = await fetchStyles({})
    const { Tees, Sweats } = styles.reduce(
      (a, c) => {
        a[c.styleName].push(c)
        return a
      },
      { Tees: [], Sweats: [] },
    )
    set({ styles, Tees, Sweats })
  },
}))
// export const useStyleStoreActions = useStyleStore((state) => state.actions)
export default useStyleStore
