import Logo from './Logo.svg'
import MobBanner from './mob-banner.jpg'
import Banner from './banner.png'
import Girl from './girl.png'
import Clothes from './clothes.png'
import ProductBanner from './afterlogin-home-banner.png'
import ProductThumb1 from './banner-thumb-1.png'
import ProductThumb2 from './banner-thumb-2.png'
import CardThumb from './card-thumb.png'
import CardImg1 from './card-img-1.png'
import StoryImg from './story-img.jpg'
import DetailsImg from './details-img.jpg'
import FitImg from './fit-img.png'
import NotFound from './default.png'
import AboutUsBg from './about-us-bg.jpg'
import SignBG from './sign.svg'
import FilterIcon from './icons/filter.svg'
import MapIcon from './icons/map.svg'
import ContactUsBg from './contact-us/contact-us-bg.png'
import BannerMobile from './banner-mob.jpg'

const HomePageBanner = 'https://stdrec.s3.amazonaws.com/assets/images/HomepageBanner_1340x650.jpg'
const HomeTile1 = 'https://stdrec.s3.amazonaws.com/assets/images/HomeTile1_500x550.jpg'
const HomeTile2 = 'https://stdrec.s3.amazonaws.com/assets/images/HomeTile2_500x550.jpg'
const ShopAllBanner = 'https://stdrec.s3.amazonaws.com/assets/images/ShopallBanner_1340x650.jpg'
const ShopTees = 'https://stdrec.s3.amazonaws.com/assets/images/shopsweats_655x420.jpg'
const ShopSweats = 'https://stdrec.s3.amazonaws.com/assets/images/shoptees_655x420.jpg'

export const IMAGES = {
  Logo,
  MobBanner,
  Banner,
  Girl,
  Clothes,
  ProductBanner,
  ProductThumb1,
  ProductThumb2,
  CardThumb,
  CardImg1,
  StoryImg,
  DetailsImg,
  FitImg,
  HomePageBanner,
  HomeTile1,
  HomeTile2,
  ShopAllBanner,
  ShopSweats,
  ShopTees,
  NotFound,
  AboutUsBg,
  SignBG,
  FilterIcon,
  MapIcon,
  ContactUsBg,
  BannerMobile,
}
