import React from 'react'
import { Link } from 'react-router-dom'

const Accessibility = () => {
  return (
    <>
      <div className='terms-box'>
        <div className='container'>
          <div className='terms-wrap no-border'>
            <div className='terms-section'>
              <h3 className='heading26'>Accessibility</h3>
              <div className='terms-section-para'>
                <p className='heading16'>
                  Standard Recreation is committed to providing an accessible website experience for
                  all users, including those with disabilities. We are constantly striving to
                  improve the accessibility of our site and adhere to the World Wide Web
                  Consortium&apos;s Web Content Accessibility Guidelines 2.1 Level AA (WCAG 2.1 AA).
                  Please note that WCAG 2.1 AA guidelines are continuously evolving, and we are
                  committed to ensuring that our website is accessible to all users. If you
                  encounter any accessibility issues, please contact us at
                  <Link
                    className='ms-1'
                    onClick={() => {
                      window.location.href = 'info@standardrecreation.com'
                    }}
                  >
                    info@standardrecreation.com
                  </Link>
                  , and we will make every effort to update the affected page(s) and ensure their
                  accessibility.
                </p>
                <p className='heading16'>General Accessibility Quick Tips</p>
                <p className='heading16'>
                  We have provided some quick tips below to help you customize your web browsing
                  experience according to your preferences:
                </p>
              </div>
            </div>
            <div className='terms-section'>
              <h4 className='heading20'>General Accessibility Quick Tips</h4>
              <div className='terms-section-para'>
                <p className='heading16'>
                  <strong>How to zoom in screen:</strong> In order to see things better this feature
                  allows user to users to enlarge their screen. Here are some tips for setting it
                  up:{' '}
                </p>
                <ul className='dots-list ps-4'>
                  <li>
                    <p className='heading16'>
                      On Windows, press the Windows key and plus sign (+) at the same time to zoom
                      in. You can also use the &quot;Magnifier&quot; application under
                      &quot;Accessories&quot;.
                    </p>
                    <p className='heading16'>
                      On Mac, go to &quot;System Preferences&quot;, open &quot;Universal
                      Access&quot; and select the &quot;Seeing&quot; tab. Check the box under
                      &quot;Zoom&quot; to &quot;On&quot;.
                    </p>
                    <p className='heading16'>
                      On your Apple device, tap &quot;Settings&quot;, then &quot;General&quot;, then
                      &quot;Accessibility&quot;. Tap the &quot;Zoom&quot; button and toggle it
                      &quot;On&quot;. Also, on Apple you can use &quot;Ctrl&quot; (&quot;Cmd&quot;
                      on Mac) plus the plus (+) or minus (-) keys when you&apos;re in a web browser.
                    </p>
                  </li>
                </ul>
              </div>
              <div className='terms-section-para'>
                <p className='heading16'>
                  <strong>How to change color contrast and fonts:</strong> Changing colors or fonts
                  (or a mix of both) help many users see text more clearly: up:{' '}
                </p>
                <ul className='dots-list ps-4'>
                  <li>
                    <p className='heading16'>
                      In Internet Explorer, go to &quot;Tools&quot;, then &quot;Internet
                      Options&quot;, on to &quot;General&quot;. From here, select &quot;Colors,
                      Fonts, or Accessibility&quot;.
                    </p>
                    <p className='heading16'>
                      In Mozilla Firefox, choose &quot;Options&quot;, then &quot;Content&quot;, then
                      finally &quot;Fonts & Colors&quot;.
                    </p>
                    <p className='heading16'>
                      In Safari, select &quot;Preferences&quot;, &quot;Appearance&quot; tab, and
                      then hit the &quot;Select…&quot; button next to the font fields.
                    </p>
                    <p className='heading16'>
                      In Chrome, go to &quot;Preferences&quot;, activate the &quot;Show advanced
                      settings&quot; link, go to the &quot;Web content&quot; heading and change the
                      &quot;Font size&quot;, &quot;Customize fonts&quot; in other ways, or set
                      default &quot;Page zoom&quot;.
                    </p>
                  </li>
                </ul>
              </div>
              <div className='terms-section-para'>
                <p className='heading16'>
                  <strong>How to find a screen reader:</strong>
                </p>
                <ul className='dots-list ps-4'>
                  <li>
                    <p className='heading16'>
                      A screen reader is text-to-speech software that enables those who are blind or
                      low vision to access information on a computer or mobile device. The
                      technology reads the screen aloud. Free screen readers are available such as
                      NVDA for Windows and VoiceOver for Mac. Jaws for Windows is also widely used.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accessibility
