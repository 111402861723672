import { NavLink } from 'react-router-dom'
import { IMAGES } from '../../../assets/images/images'
import { PAGE_PATH } from '../../../router/paths.js'

const Footer = () => {
  return (
    <footer className='footer-cntr'>
      <div className='container-fluid custom-container-fluid'>
        <article className='footerBox'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='footer-logo'>
                <NavLink to='/'>
                  <img src={IMAGES.Logo} alt='logo' />
                </NavLink>
                <h4>
                  Exceptional apparel for <br />
                  exceptional brands
                </h4>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='block-links-wrap'>
                <ul className='block-links'>
                  <li className='item-list'>
                    <NavLink to='#' className='nav-link'>
                      Links
                    </NavLink>
                  </li>
                  <li className='item-list'>
                    <NavLink to={PAGE_PATH.about_us} className='nav-link'>
                      About Us
                    </NavLink>
                  </li>
                  <li className='item-list'>
                    <NavLink to={PAGE_PATH.contact_us} className='nav-link'>
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>
      </div>
      <article className='rights-reserve'>
        <p>© STANDARD RECREATION. All Rights Reserved 2022-2023</p>
        <ul className='footer-link'>
          <li className='list-item'>
            <NavLink to={PAGE_PATH.privacy_policy} className='nav-link'>
              Privacy Policy
            </NavLink>
          </li>
          <li className='list-item'>
            <NavLink to={PAGE_PATH.accessibility} className='nav-link'>
              Accessibility
            </NavLink>
          </li>
          <li className='list-item'>
            <NavLink to={PAGE_PATH.terms_conditions} className='nav-link'>
              Terms of Service
            </NavLink>
          </li>
        </ul>
      </article>
    </footer>
  )
}

export default Footer
