import React from 'react'
import { hydrate, render } from 'react-dom'
// import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MOCKS } from './config'
import AuthProvider from './contexts/AuthContext'
import { queryClient } from './react-query'
import './assets/css/external.css'
import './assets/scss/main.scss'
import ParentProvider from './contexts/ParentContext'
import * as SEO from './seo'

// const root = ReactDOM.createRoot(document.getElementById('root'))

if (MOCKS) {
  const { worker } = require('../src/_mocks/browser')
  worker.start()
}

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV !== 'production') {
  SEO.preventIndexing()
}else{
  SEO.addScripts()
  // hide all console messages
  console.log = console.warn = console.error = () => {}
}

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ParentProvider>
            <HelmetProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
            </HelmetProvider>
          </ParentProvider>
        </AuthProvider>
      </QueryClientProvider>
    </React.StrictMode>,
    rootElement,
  )
} else {
  render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ParentProvider>
            <HelmetProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
            </HelmetProvider>
          </ParentProvider>
        </AuthProvider>
      </QueryClientProvider>
    </React.StrictMode>,
    rootElement,
  )
}

reportWebVitals()
