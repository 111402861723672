import { Spinner } from 'react-bootstrap'
import style from './Loader.module.scss'

const LoadingScreen = () => {
  return (
    <div className={`${style.loaderFullPage} d-flex align-items-center justify-content-center`}>
      <div className={'text-center'}>
        <Spinner animation='border' />
      </div>
    </div>
  )
}

export default LoadingScreen
