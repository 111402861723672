const ImageAsset = (props) => {
  const { src, alt, ...rest } = props

  const onError = (event) => {
    event.target.style.display = 'none'
  }

  return <img src={src} onError={onError} alt={alt} {...rest} />
}

export default ImageAsset
