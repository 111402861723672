import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import LoadingScreen from '../components/LoaderScreen/LoadingScreen'
import { useAuth } from '../contexts/AuthContext'
import { PAGE_PATH } from '../router/paths'
// import LoginPage from '../pages/LoginPage'

const AuthGuard = ({ children }) => {
  const { isInitialized, isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null)
  if (!isInitialized) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    return <Navigate to={PAGE_PATH.home} />
  }

  // if (!isAuthenticated) {
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname)
  //   }
  //   return <LoginPage />
  // }

  // if (requestedLocation && pathname !== requestedLocation) {
  //   setRequestedLocation(null)
  //   return <Navigate to={requestedLocation} />
  // }

  return <>{children}</>
}

export default AuthGuard
