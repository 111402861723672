import * as yup from 'yup'

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is requred'),
  })
  .required()

export default schema
