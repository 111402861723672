/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { lazy, Suspense } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import LoadingScreen from '../components/LoaderScreen/LoadingScreen'
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
import MainLayout from '../layouts/MainLayout/MainLayout'
import { ACCOUNT_PATH, AUTH_PATH, PAGE_PATH, REDIRECT_PATH } from './paths'
import CommonGuard from '../guards/CommonGaurd'
// import ForgotPasswordPage from '../pages/Login/ForgotPasswordPage'
// import ResetPasswordPage from '../pages/Login/ResetPasswordPage'
import ResetPasswordPage from '../pages/Auth/ResetPassword'
import TermsConditions from '../pages/TermsConditions/Termsconditions'
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy'
import Accessibility from '../pages/Accessibility/Accessibility'
import UserGuard from '../guards/UserGuard'
import GuestLayout from '../layouts/GuestLayout/GuestLayout'
import RedirectToElementorSite from '../pages/Elementor'

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

function Router() {
  // const HomePage = Loadable(lazy(() => import('../pages/Home/HomePage')))
  const ShopAllPage = Loadable(lazy(() => import('../pages/Products/Products')))
  const ProductListPage = Loadable(lazy(() => import('../pages/Product/ProductListPage')))
  const ProductDetailPage = Loadable(lazy(() => import('../pages/Product/ProductDetailPage')))
  const OrderConfirmPage = Loadable(lazy(() => import('../pages/Order/OrderConfirm')))
  const OrderCancelPage = Loadable(lazy(() => import('../pages/Order/OrderCancel')))
  const CheckoutPage = Loadable(lazy(() => import('../pages/Checkout/Checkout')))
  const AccountPage = Loadable(lazy(() => import('../pages/Account/Account')))
  const OrderDetailPage = Loadable(lazy(() => import('../pages/Account/OrderDetailPage')))
  const AboutUsPage = Loadable(lazy(() => import('../pages/About/About')))
  const ContactUsPage = Loadable(lazy(() => import('../pages/ContactUs/ContactUs')))
  const PreviewLaunchPage = Loadable(lazy(() => import('../pages/PreviewLink')))

  // Auth pages
  const LoginPage = Loadable(lazy(() => import('../pages/Auth/Login')))
  const NewUserLoginPage = Loadable(lazy(() => import('../pages/Auth/NewUserLoginV2')))
  const ForgotPasswordPage = Loadable(lazy(() => import('../pages/Auth/ForgotPassword')))

  return useRoutes([
    {
      path: AUTH_PATH.root,
      element: (
        <GuestGuard>
          <GuestLayout />
        </GuestGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={AUTH_PATH.login} replace />,
        },
        {
          path: AUTH_PATH.login,
          element: <LoginPage />,
        },
        {
          path: AUTH_PATH.newUser,
          element: <NewUserLoginPage />,
        },
        {
          path: AUTH_PATH.forgotPassword,
          element: <ForgotPasswordPage />,
        },
        {
          path: AUTH_PATH.resetPassword,
          element: <ResetPasswordPage />,
        },
        { path: '*', element: <Navigate to={AUTH_PATH.login} replace /> },
      ],
    },
    {
      path: 'auth/reset-password/:token',
      element: (
        <GuestGuard>
          <MainLayout />
        </GuestGuard>
      ),
      children: [
        {
          index: true,
          element: <ResetPasswordPage />,
        }
      ],
    },
    {
      path: PAGE_PATH.redirectToElementor,
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {
          index: true,
          element: <RedirectToElementorSite />,
        }
      ],
    },
    {
      path: PAGE_PATH.about_us,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <AboutUsPage />,
        },
      ],
    },
    {
      path: PAGE_PATH.contact_us,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <ContactUsPage />,
        },
      ],
    },
    {
      path: PAGE_PATH.terms_conditions,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <TermsConditions />,
        },
      ],
    },
    {
      path: PAGE_PATH.accessibility,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Accessibility />,
        },
      ],
    },
    {
      path: PAGE_PATH.privacy_policy,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <PrivacyPolicy />,
        },
      ],
    },
    {
      path: REDIRECT_PATH.orderSuccess,
      element: (
        <CommonGuard>
          <Outlet />
        </CommonGuard>
      ),
      children: [
        {
          index: true,
          element: <OrderConfirmPage />,
        }
      ],
    },
    {
      path: REDIRECT_PATH.orderCancel,
      element: (
        <CommonGuard>
          <Outlet />
        </CommonGuard>
      ),
      children: [
        {
          index: true,
          element: <OrderCancelPage />,
        }
      ],
    },
    {
      path: PAGE_PATH.preview,
      element: (
        <CommonGuard>
          <Outlet />
        </CommonGuard>
      ),
      children: [
        {
          index: true,
          element: <PreviewLaunchPage />,
        }
      ],
    },
    // {
    //   path: AUTH_PATH.root,
    //   element: <MainLayout />,
    //   children: [
    //     // { element: <Navigate to={AUTH_PATH.login} replace />, index: true },
    //     // {
    //     //   path: AUTH_PATH.login,
    //     //   element: (
    //     //     <GuestGuard>
    //     //       <LoginPage />
    //     //     </GuestGuard>
    //     //   ),
    //     // },
    //     {
    //       path: AUTH_PATH.forgotPassword,
    //       element: (
    //         <GuestGuard>
    //           <ForgotPasswordPage />
    //         </GuestGuard>
    //       ),
    //     },
    //     {
    //       path: AUTH_PATH.resetPassword,
    //       element: (
    //         <GuestGuard>
    //           <ResetPasswordPage />
    //         </GuestGuard>
    //       ),
    //     },
    //   ],
    // },
    {
      path: ACCOUNT_PATH.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={ACCOUNT_PATH.list('Tees,Sweats')} replace />, index: true },
        {
          path: ACCOUNT_PATH.shopAll,
          element: <ShopAllPage />,
        },
        {
          path: ACCOUNT_PATH.list(''),
          element: <ProductListPage />,
        },
        {
          path: ACCOUNT_PATH.list(':style'),
          element: <ProductListPage />,
        },
        {
          path: ACCOUNT_PATH.detail(':style', ':parentId', ':childId', ':templateId'),
          element: <ProductDetailPage />,
        },
        // {
        //   path: ACCOUNT_PATH.orderDetail(':parentId', ':orderId'),
        //   element: <OrderDetailPage />,
        // },
        // { path: ACCOUNT_PATH.account(':tab'), element: <AccountPage /> },
        // { path: ACCOUNT_PATH.checkout(':parentId'), element: <CheckoutPage /> },
        { path: '*', element: <Navigate to={ACCOUNT_PATH.shopAll} replace /> },
      ],
    },
    {
      path: ACCOUNT_PATH.accountRoot,
      element: (
        <UserGuard>
          <MainLayout />
        </UserGuard>
      ),
      children: [
        {
          path: ACCOUNT_PATH.orderDetail(':parentId', ':orderId'),
          element: <OrderDetailPage />,
        },
        { path: ACCOUNT_PATH.account(':tab'), element: <AccountPage /> },
        { path: ACCOUNT_PATH.checkout(':parentId'), element: <CheckoutPage /> },
        { path: '*', element: <Navigate to={ACCOUNT_PATH.account(':tab')} replace /> },
      ]
    },
    // { path: '/', element: <Navigate to={PAGE_PATH.home} /> },
  ])
}
export default Router
