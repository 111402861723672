/* eslint-disable no-unused-vars */
import client from '../utils/axios'


export const addSalesUseTaxForm = (payload) => {
  return client.post('/api/sales-use-tax-form', payload)
}

export const deleteSalesUseTaxForm = (id) => {
  return client.delete(`/api/sales-use-tax-form/${id}`)
}



