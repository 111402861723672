import { rest } from 'msw'
import db from '../db'
import { successResponse } from '../extras/helpers'

const styles = [
  rest.get('/api/styles', (req, res, ctx) => {
    const data = db.styles.getAll()
    return successResponse({ res, ctx, data })
  }),
]
export default styles
