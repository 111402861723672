/* eslint-disable max-lines */
import { factory, primaryKey, nullable } from '@mswjs/data'

const accounts = factory({
  users: {
    id: primaryKey(Number),
    type: String,
    email: String,
    password: String,
    name: String,
    role: String,
    accessToken: String,
  },
  parents: {
    id: primaryKey(Number),
    accountName: String,
    contactName: String,
    contactEmail: String,
    contactPhone: String,
    userId: Number,
  },
  childs: {
    id: primaryKey(Number),
    parentId: Number,
    customerAccountName: String,
    category: String,
    locationId: Number,
    tags: nullable(String),
  },
  buyers: {
    id: primaryKey(Number),
    parentId: Number,
    userId: Number,
  },
  buyerLocations: {
    id: primaryKey(Number),
    childId: Number,
    userId: Number,
  },
})

const users = [
  {
    id: 1,
    type: 'NEW_USER',
    email: 'ava@gmail.com',
    password: '1234567890',
    name: 'Ava Hudson',
    role: 'CP',
    accessToken: 'xxx',
  },
  {
    id: 2,
    type: 'NEW_USER',
    email: 'lily@gmail.com',
    password: '1234567890',
    name: 'lily Hudson',
    role: 'CP',
    accessToken: 'xxx',
  },
  {
    id: 3,
    type: 'NEW_USER',
    email: 'harry@gmail.com',
    password: '1234567890',
    name: 'Harry Hudson',
    role: 'CP',
    accessToken: 'xxx',
  },
  {
    id: 4,
    type: 'VERIFIED_USER',
    email: 'evans@gmail.com',
    password: '1234567890',
    name: 'Evans Miller',
    role: 'SP',
    accessToken: 'xxx',
  },
  {
    id: 5,
    type: 'VERIFIED_USER',
    email: 'jeff@gmail.com',
    password: '1234567890',
    name: 'Jeff Cheko',
    role: 'SP',
    accessToken: 'xxx',
  },
  {
    id: 6,
    type: 'VERIFIED_USER',
    email: 'john@gmail.com',
    password: '1234567890',
    name: 'John Deo',
    role: 'SP',
    accessToken: 'xxx',
  },
  {
    id: 7,
    type: 'VERIFIED_USER',
    email: 'paul@gmail.com',
    password: '1234567890',
    name: 'Paul Longo',
    role: 'PA',
    accessToken: 'xxx',
  },
  {
    id: 8,
    type: 'VERIFIED_USER',
    email: 'david@gmail.com',
    password: '1234567890',
    name: 'David Warner',
    role: 'PA',
    accessToken: 'xxx',
  },
  {
    id: 9,
    type: 'VERIFIED_USER',
    email: 'cris@gmail.com',
    password: '1234567890',
    name: 'Cris Raidge',
    role: 'PA',
    accessToken: 'xxx',
  },
]

const parents = [
  {
    id: 1,
    accountName: 'Nantucket Boat Basin',
    contactName: 'Paul Longo',
    contactEmail: 'paul@gmail.com',
    contactPhone: '1234567890',
    userId: 1,
  },
  {
    id: 2,
    accountName: 'Hyatt Royal',
    contactName: 'David Warner',
    contactEmail: 'david@gmail.com',
    contactPhone: '1234567890',
    userId: 1,
  },
  {
    id: 3,
    accountName: 'Cisco Brewers',
    contactName: 'Cris Raidge',
    contactEmail: 'cris@gmail.com',
    contactPhone: '1234567890',
    userId: 1,
  },
]

const childs = [
  {
    id: 1,
    parentId: 1,
    customerAccountName: 'Nantucket Boat Basin',
    category: 'Resort',
    locationId: 1,
    tags: null,
  },
  {
    id: 2,
    parentId: 2,
    customerAccountName: 'Home Brew',
    category: 'Resort',
    locationId: 1,
    tags: null,
  },
  {
    id: 3,
    parentId: 2,
    customerAccountName: 'ACC CSU',
    category: 'Resort',
    locationId: 1,
    tags: null,
  },
  {
    id: 4,
    parentId: 3,
    customerAccountName: 'Cisco Brewers - Nantucket',
    category: 'Food & Drink',
    locationId: 1,
    tags: null,
  },
]

const buyers = [
  {
    id: 1,
    parentId: 7,
    userId: 1,
  },
  {
    id: 2,
    parentId: 8,
    userId: 2,
  },
  {
    id: 3,
    parentId: 9,
    userId: 3,
  },
]

const buyerLocations = [
  {
    id: 1,
    childId: 1,
    userId: 1,
  },
  {
    id: 2,
    childId: 2,
    userId: 2,
  },
  {
    id: 3,
    childId: 3,
    userId: 2,
  },
  {
    id: 4,
    childId: 4,
    userId: 3,
  },
]

users.forEach((user) => accounts.create(user))
parents.forEach((parent) => accounts.parents.create(parent))
childs.forEach((child) => accounts.childs.create(child))
buyers.forEach((buyer) => accounts.buyers.create(buyer))
buyerLocations.forEach((buyerLocation) => accounts.buyerLocations.create(buyerLocation))

export default accounts
