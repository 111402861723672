import { Navigate } from 'react-router-dom'
import LoadingScreen from '../components/LoaderScreen/LoadingScreen'
import { useAuth } from '../contexts/AuthContext'
import { PAGE_PATH, ACCOUNT_PATH } from '../router/paths'
// import LoginPage from '../pages/LoginPage'

const UserGuard = ({ children }) => {

  const { isInitialized, isAuthenticated, user } = useAuth()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    return <Navigate to={PAGE_PATH.home} />
  }

  if (isAuthenticated && !user.isUser) {
    return <Navigate to={ACCOUNT_PATH.list('Tees,Sweats')} />
  }

  return <>{children}</>
}


export default UserGuard
