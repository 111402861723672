import { useAuth } from '../contexts/AuthContext'
import LoadingScreen from '../components/LoaderScreen/LoadingScreen'

const CommonGuard = ({ children }) => {
  const { isInitialized } = useAuth()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return <>{children}</>
}

export default CommonGuard
