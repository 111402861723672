/* eslint-disable max-len */
import { Modal } from 'react-bootstrap'
// import { toast } from 'react-hot-toast'
// import { publicIpv4 } from 'public-ip'
// import { loadStripe } from '@stripe/stripe-js'
import { useCartQuery, useRemoveFromCartMutation } from '../../react-query/cart'
import CartItem from './CartItem'
// import { useCheckoutMutation } from '../../react-query/order'
import { useParent } from '../../contexts/ParentContext'
import { useNavigate } from 'react-router-dom'
import { ACCOUNT_PATH } from '../../router/paths'
import { formatMoney } from '../../utils/date-time'

const CheckoutModal = (props) => {
  const { parentId } = useParent()
  const navigate = useNavigate()
  const { mutate, isLoading: isRemoving } = useRemoveFromCartMutation()
  // const {
  //   mutate: checkout,
  //   isLoading: isCheckouting,
  //   // isError,
  //   // isSuccess,
  //   // error,
  // } = useCheckoutMutation()
  const { show, setShow } = props
  const { data: cartItems } = useCartQuery(parentId)
  const total = cartItems?.reduce((total, cartItem) => {
    total += parseInt(cartItem.price)
    return total
  }, 0)

  const isReadyToCheckout =
    cartItems?.every((cartItem) => {
      return (
        !cartItem.isDummy && // addToCart progress check
        !Number.isNaN(cartItem.qty) &&
        !Number.isNaN(cartItem.oldPrice) &&
        !cartItem.qty <= 0 &&
        !cartItem.price <= 0
      )
    }) && !isRemoving // removeFromCart progress check

  const handleCheckout = async () => {
    if (!isReadyToCheckout) return
    setShow(false)
    navigate(ACCOUNT_PATH.checkout(parentId))
    // const ipAddress = await publicIpv4()
    // const bua = window.navigator.userAgent
    // const items = cartItems.map((cartItem) => {
    //   return { ...cartItem, quantity: cartItem.qty, price: cartItem.discountedPrice }
    // })
    // checkout(
    //   { bua, ipAddress, total, items },
    //   {
    //     onSuccess: async (session) => {
    //       // toast.success('Order created')
    //       // queryClient.setQueryData(['carts'], [])
    //       const stripe = await loadStripe(
    //         // eslint-disable-next-line max-len
    //         'pk_test_51MR8jqSH75wK12A9X4RSZuDTYECClcvIgUfXSH8jY3chjqpPGVOTJ6ofAgNkOw4OpQHgxROhZhD027vkUfz5obpo00XtNvXpf2',
    //       )
    //       const result = stripe.redirectToCheckout({
    //         sessionId: session.id,
    //       })
    //       if (result.error) {
    //         console.log(result.error)
    //         toast.error(result.error)
    //       }
    //     },
    //     onError: (err) => {
    //       toast.error(err)
    //     },
    //   },
    // )
  }

  const navigateToShopAllPage = () => {
    navigate(ACCOUNT_PATH.list('Tees,Sweats'))
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName='checkout-modal-dialog'
      aria-labelledby='example-custom-modal-styling-title'
      backdropClassName={'checkout-backdrop zIndex9'}
      className={'checkout-modal zIndex99'}
    >
      <Modal.Body>
        <button type={'button'} className={'btn checkout-close'} onClick={() => setShow(false)}>
          <i className={'icon-cross'}></i>
        </button>
        <div className='mini-cart'>
          <div>
            <div className='mini-cart-header'>
              <h5>My Bag</h5>
            </div>
            <div className='mini-cart-body'>
              {cartItems?.map((cartItem) => (
                <CartItem
                  key={cartItem.id}
                  cartItem={cartItem}
                  hide={() => setShow(false)}
                  parentId={parentId}
                  remove={mutate}
                />
              ))}
            </div>
            {cartItems?.length ? (
              <div className='mini-cart-footer'>
                <div className='sub-total'>
                  <span className='label'>Sub-total</span>
                  <span className='value'>${formatMoney(total)}</span>
                </div>
                <div className='shipping'>
                  <span className='label'>Shipping</span>
                  <span className='value'>Calculated at checkout</span>
                </div>
                <div className='btn-holder'>
                  <button
                    type='button'
                    className='btn btn-blue btn-medium w-100'
                    disabled={!isReadyToCheckout}
                    onClick={handleCheckout}
                  >
                    {isReadyToCheckout ? 'Check out' : 'Updating Cart...'}
                  </button>
                </div>
              </div>
            ) : (
              <div className='empty-cart'>
                <div className='mini-cart-header empty-cart-box'>
                  <h5>Your bag is empty</h5>
                  <span className='label'>Lets Change that</span>
                  <div className='btn-holder'>
                    <button
                      type='button'
                      className='btn btn-blue btn-medium w-100'
                      onClick={navigateToShopAllPage}
                    >
                      Shop Now
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CheckoutModal
