import { useMemo } from 'react'
import { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import { useParent } from '../../contexts/ParentContext'
import useParentStore from '../../store/useParentStore'
import { ACCOUNT_PATH } from '../../router/paths'
import { SESSION_STORAGE_KEYS } from '../../config/constants'
const ParentDropdown = ({ open, onOpen, onHide }) => {
  const { parentId, setParentId } = useParent()
  const parents = useParentStore((state) => state.parents)
  const parentMap = useParentStore((state) => state.parentMap)
  const location = useLocation()
  const { parentId: _parentId } = useParams()

  const isDisabled = useMemo(() => {
    const url = location.pathname
    if (url.startsWith('/account/list')) {
      return false
    } else if (url.startsWith('/account/account/order-detail')) {
      return true
    } else if (url.startsWith('/account/account/checkout/')) {
      return true
    } else if (url.startsWith('/account/account')) {
      return false
    } else if (url === '/' || url === ACCOUNT_PATH.shopAll) {
      return false
    }
    return true
  }, [location.pathname])

  useEffect(() => {
    onHide()
  }, [])

  /*
   * change parentId if
   * parent dropdown is enabled for this path and
   * set first parent from list of parents
   */

  useEffect(() => {
    const firstParentId = parents[0]?.id
    if (parentId) {
      return
    }
    if (!isDisabled && firstParentId) {
      setParentId(firstParentId)
    }
  }, [parents, isDisabled])

  /*
   * change parentId if
   * url params { _parentId } change and
   * previous and new parentId is different
   */
  useEffect(() => {
    if (parentId == _parentId) {
      return
    }
    if (_parentId) {
      setParentId(_parentId)
    }
  }, [location.pathname])

  /*
   * store parentId in session storage if
   * change
   */
  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.PARENT_ID, String(parentId))
  }, [parentId])

  return (
    <div>
      <Dropdown show={open} className={'custom-dropdown nav-dropdown'}>
        <Dropdown.Toggle
          variant='link'
          id='dropdown-basic'
          className={'btn list-item text-truncate'}
          disabled={isDisabled}
          onClick={onOpen}
        >
          <i className='icon-store align-middle'></i>{' '}
          {parentMap[parentId]?.accountName || 'Select Parent'}
          <span className='material-symbols-outlined down-arrow d-block d-lg-none'>
            arrow_drop_down
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className='sub-header-dropdown'>
          <div className='dropdown-header d-block d-lg-none'>
            <div className='dropdown-header-inner'>
              <h5 className='header-text'>Select Account</h5>
              <button className='cross-btn'>
                <span className='icon-cross' onClick={onHide}></span>
              </button>
            </div>
          </div>
          <div className='dropdown-list-cntr'>
            {parents.map((parent) => (
              <Dropdown.Item
                key={parent.id}
                onClick={() => {
                  setParentId(parent.id)
                  onHide()
                }}
              >
                {parent.accountName}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ParentDropdown
