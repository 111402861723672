// string utilities
export const capFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const truncate = (input, len = 5) =>
  input.length > len ? `${input.substring(0, len)}...` : input

export const fColor = (name) => name?.split('-')?.[0]

export const decodeBase64 = (string) => {
  try{
    if(!string?.trim()) throw null
    return atob(string)
  }catch(err){
    return false
  }
}
