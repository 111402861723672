/* eslint-disable max-len */
import * as React from 'react'
import Lottie from 'react-lottie'
import { Outlet } from 'react-router-dom'
import { capeCodHouseSlower } from '../../lotties'
import styles from './Guest.module.scss'
import { ELEMENTOR_URL } from '../../config'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: capeCodHouseSlower,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const goToStdRec = () => window.location.replace(ELEMENTOR_URL)

const GuestLayout = () => {
  return (
    <div className={styles.layoutWrap}>
      <div className={styles.backBtnWrap}>
        <button className={styles.backBtn} onClick={goToStdRec}>
          <i className='fa-solid fa-arrow-left'></i>
          Back to standardrec.com
        </button>
      </div>
      <Outlet />
      <div className={styles.lottieWrap}>
        <Lottie options={defaultOptions}></Lottie>
      </div>
    </div>
  )
}

export default GuestLayout
