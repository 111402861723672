

export function addScripts() {
   // ---------- Meta Pixel Code Starts ----------
    // Add the Meta Pixel script
    const fbPixelScript = document.createElement('script')
    fbPixelScript.type = 'text/javascript'
    fbPixelScript.textContent = `
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '340947242209730');
    fbq('track', 'PageView');
    `
    document.head.appendChild(fbPixelScript)

    // Add the noscript tag with fallback image for users without JavaScript
    const fbNoscriptTag = document.createElement('noscript')
    fbNoscriptTag.innerHTML = `
    <img height="1" width="1" style="display:none;" alt=""
        src="https://www.facebook.com/tr?id=340947242209730&ev=PageView&noscript=1" />
    `
    document.body.appendChild(fbNoscriptTag)
   // ---------- Meta Pixel Code Ends ------------



    // ---------- Google Tag Code Starts ----------
    // Create the first script tag for the Google Tag Manager script (async)
    const gtagScript = document.createElement('script')
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-S3V5ZNPM9T'
    gtagScript.async = true
    document.head.appendChild(gtagScript)

    // Create the second script tag with the inline configuration for gtag
    const gtagInlineScript = document.createElement('script')
    gtagInlineScript.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
        dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-S3V5ZNPM9T');
    `
    document.head.appendChild(gtagInlineScript)
   // ---------- Google tag Code Ends ------------



    // ---------- LinkedIn Partner Script Starts ----------
    // First inline script for LinkedIn partner ID
    const linkedinPartnerScript = document.createElement('script')
    linkedinPartnerScript.type = 'text/javascript'
    linkedinPartnerScript.textContent = `
        _linkedin_partner_id = '5603788';
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `
    document.head.appendChild(linkedinPartnerScript)

    // Second inline script for LinkedIn tracking
    const linkedinTrackingScript = document.createElement('script')
    linkedinTrackingScript.type = 'text/javascript'
    linkedinTrackingScript.textContent = `
        ;(function (l) {
        if (!l) {
            window.lintrk = function (a, b) {
            window.lintrk.q.push([a, b]);
            };
            window.lintrk.q = [];
        }
        var s = document.getElementsByTagName('script')[0];
        var b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode.insertBefore(b, s);
        })(window.lintrk);
    `
    document.head.appendChild(linkedinTrackingScript)

    // Adding the noscript tag with fallback image for users without JavaScript
    const linkedinNoScript = document.createElement('noscript')
    linkedinNoScript.innerHTML = `
        <img height="1" width="1" style="display:none;" alt=""
        src="https://px.ads.linkedin.com/collect/?pid=5603788&fmt=gif" />
    `
    document.body.appendChild(linkedinNoScript)
   // ---------- LinkedIn Partner Script Ends ------------
}

export function preventIndexing(){
    const robotsMeta = document.createElement('meta')
    robotsMeta.name = 'robots'
    robotsMeta.content = 'noindex'

    const googlebotMeta = document.createElement('meta')
    googlebotMeta.name = 'googlebot'
    googlebotMeta.content = 'noindex'

    document.head.appendChild(robotsMeta)
    document.head.appendChild(googlebotMeta)
}
