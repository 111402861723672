import { ACCOUNT_TABS } from '../config/constants'

function path(root, sublink) {
  return `${root}${sublink}`
}

const AUTH = '/auth/v2'
const ACCOUNT = '/account'

export const AUTH_PATH = {
  root: AUTH,
  login: path(AUTH, '/login'),
  forgotPassword: path(AUTH, '/forgot-password'),
  resetPassword: path(AUTH, '/reset-password/:token'),
  newUser: path(AUTH, '/new-user')
}

export const PAGE_PATH = {
  home: '/',
  preview: '/preview/:id',
  about_us: '/about_us',
  contact_us: '/contact_us',
  terms_conditions: '/terms-of-service',
  privacy_policy: '/privacy-policy',
  accessibility: '/accessibility',
  redirectToElementor: '/redirect?' ,
}

export const ACCOUNT_PATH = {
  root: ACCOUNT,
  accountRoot: path(ACCOUNT, '/account'),
  shopAll: path(ACCOUNT, '/shop-all'),
  account: (tab = ACCOUNT_TABS.orderHistory) => path(ACCOUNT, `/account/${tab}`),
  orderDetail: (parentId, orderId) =>
    path(ACCOUNT, `/account/${ACCOUNT_TABS.orderDetail}/${parentId}/${orderId}`),
  orders: path(ACCOUNT, '/orders'),
  checkout: (parentId) => path(ACCOUNT, `/account/checkout/${parentId}`),
  list: (style) => path(ACCOUNT, `/list/${style}`),
  detail: (style, parentId, childId, templateId) =>
    path(ACCOUNT, `/${style}/${parentId}/${childId}/${templateId}`),
  orderSuccess: path(ACCOUNT, '/order-success'),
  orderCancel: path(ACCOUNT, '/order-cancel'),
  productDetails: path(ACCOUNT, '/product-details'),
  shippingAddress: path(ACCOUNT, '/shipping-address'),
}

export const REDIRECT_PATH = {
  orderSuccess: '/order-success',
  orderCancel: '/order-cancel',
}
