import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../contexts/AuthContext'
import {
  addBuyer,
  changePassword,
  checkEmail,
  fetchProfileByEmail,
  fetchProfileMe,
  getBuyers,
  login,
  register,
  removeBuyer,
  updateBuyer,
  updateUserProfile,
} from '../services/userService'
import { useParent } from '../contexts/ParentContext'

export const useMutationLogin = (options = {}) => {
  return useMutation(login, options)
}

export const useMutationCheckEmail = (options = {}) => {
  return useMutation(checkEmail, options)
}

export const useMutationChangePassword = (options = {}) => {
  return useMutation(changePassword, options)
}

export const useMutationFetchProfileByEmail = (options = {}) => {
  return useMutation(fetchProfileByEmail, options)
}

export const useMutationFetchProfileMe = (options = {}) => {
  return useMutation(fetchProfileMe, options)
}

export const useUserProfileMutation = () => {
  const { initialize } = useAuth()
  return useMutation({
    mutationFn: updateUserProfile,
    onSettled: (user, error) => {
      if (error) {
        toast.error('Failed to update profile')
      } else {
        toast.success('Profile has been updated')
        initialize()
      }
    },
  })
}

export const useBuyersQuery = ({ parentId }) => {
  return useQuery(['buyers', parentId], () => getBuyers({ parentId }))
}

export const useRegisterMuatation = (options = {}) => {
  return useMutation({
    mutationFn: register,
    onSettled: (_, error) => {
      if (error) {
        toast.error(error)
      } else {
        toast.success('Buyer added successfully')
      }
    },
    ...options,
  })
}

export const useAddBuyerMuatation = (options = {}) => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: addBuyer,
    onSettled: (_, error) => {
      if (error) {
        toast.error(error)
      } else {
        toast.success('Buyer added successfully')
      }
      queryClient.invalidateQueries({ queryKey: ['buyers', parentId] })
    },
    ...options,
  })
}

export const useUpdateBuyerMuatation = (options = {}) => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: updateBuyer,
    onSettled: (_, error) => {
      if (error) {
        toast.error(error)
      } else {
        toast.success('Buyer added successfully')
      }
      queryClient.invalidateQueries({ queryKey: ['buyers', parentId] })
    },
    ...options,
  })
}

export const useDeleteBuyerMuatation = (options = {}) => {
  const queryClient = useQueryClient()
  const { parentId } = useParent()
  return useMutation({
    mutationFn: removeBuyer,
    onSettled: (_, error) => {
      if (error) {
        toast.error(error)
      } else {
        toast.success('Buyer removed successfully')
      }
      queryClient.invalidateQueries({ queryKey: ['buyers', parentId] })
    },
    ...options,
  })
}
