import { rest } from 'msw'
import db from '../db'
import { errorResponse, successResponse } from '../extras/helpers'

const auth = [
  rest.post('/api/users/check-email', async (req, res, ctx) => {
    const user = db.users.findFirst({ where: { email: { equals: req.body.email } } })
    if (!user) {
      return errorResponse({
        res,
        ctx,
        statusCode: 404,
        msg: 'User not registered with this email',
      })
    }
    return successResponse({ res, ctx, data: user })
  }),

  rest.post('/api/users/login', async (req, res, ctx) => {
    const user = db.users.findFirst({ where: { email: { equals: req.body.email } } })
    if (!user) {
      return errorResponse({
        res,
        ctx,
        statusCode: 404,
        msg: 'User not registered with this email',
      })
    } else if (user.password !== req.body.password) {
      return errorResponse({
        res,
        ctx,
        statusCode: 400,
        msg: 'Incorrect Password',
      })
    }
    return successResponse({ res, ctx, data: user })
  }),

  rest.post('/api/users/change-password', async (req, res, ctx) => {
    const user = db.users.findFirst({ where: { accessToken: { equals: req.body.accessToken } } })
    if (!user) {
      return errorResponse({
        res,
        ctx,
        statusCode: 400,
        msg: 'Bad Request',
      })
    }
    return successResponse({ res, ctx, data: user })
  }),

  rest.post('/api/users', async (req, res, ctx) => {
    const user = db.users.findFirst({ where: { id: { equals: 1 } } })
    if (!user) {
      return errorResponse({
        res,
        ctx,
        statusCode: 400,
        msg: 'Bad Request',
      })
    }
    return successResponse({ res, ctx, data: user })
  }),
]

export default auth
