import axios from 'axios'
import { AWS_ASSETS_URL } from '../config'

export const fetchProductInfoJson = async (style, subStyle, tabName) => {
  return await axios.get(
    `${AWS_ASSETS_URL}/files/${style}/${subStyle
      .replace(' ', '+')
      .replace('/', ':')}/${tabName}.json`,
  )
}

export const fetchProduct_details_and_fit_infoJson = async () => {
  return await axios.get(`${AWS_ASSETS_URL}/files/product_details_and_fit_info.json`)
}

export const fetchCategory_infoJson = async () => {
  return await axios.get(`${AWS_ASSETS_URL}/files/category_info.json`)
}

export const fetchTemplateInfoJson = async (style) => {
  return await axios.get(`${AWS_ASSETS_URL}/files/${style}/templateInfo.json`)
}

export const fetchAllTemplateInfoJson = async (style) => {
  return await axios.get(`${AWS_ASSETS_URL}/files/${style}/templateInfo.json`)
}

export const fetchJSONFromURLService = (url) => {
  return axios({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}