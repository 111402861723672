import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '../../../contexts/AuthContext'
import { AUTH_PATH } from '../../../router/paths'
import loginSchema from './loginSchemaV2'
import cn from 'classnames'

const LoginPopup = () => {
  const { login, isLoggingIn, loginError } = useAuth()
  const [show, setShow] = useState(false)

  const handleClick = () => setShow((prev) => !prev)

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  useEffect(() => {
    if (loginError) {
      setError('password', { type: 'api', message: loginError })
    }
  }, [loginError])

  const handleLogin = (values) => login(values)

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div className={'login-popup-menu d-block'}>
        <div className='form-group'>
          <span className='icon-msg-alt'></span>
          <input
            {...register('email')}
            type='text'
            className='form-control'
            placeholder='Enter email'
            autoComplete='off'
          />
        </div>
        <div className='form-group'>
          <span className='icon-lock-alt'></span>
          <input
            {...register('password')}
            type={show ? 'text' : 'password'}
            className='form-control'
            placeholder='Enter password'
            autoComplete='new-password'
          />
          <button type='button' className='password-view-btn' onClick={handleClick}>
            <span className={cn({ 'icon-eye-blocked': !show, 'icon-eye': show })}></span>
          </button>
          {/* <span className='icon-eye-blocked view-icon'></span>
          <span className='icon-eye view-icon'></span> */}
        </div>
        <div className='forget-password-wrap'>
          <Link disabled={isLoggingIn} to={isLoggingIn ? '#' : AUTH_PATH.forgotPassword}>
            Forgot password?
          </Link>
        </div>
        <div className='btn-controls'>
          <button type='submit' className='btn btn-red' disabled={isLoggingIn}>
            {isLoggingIn ? <Spinner size='sm' /> : 'Login'}
          </button>
        </div>
        <p className='text-start text-danger'>
          <small>{errors?.email?.message || errors?.password?.message}</small>
        </p>
      </div>
    </form>
  )
}

export default LoginPopup
