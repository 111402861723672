/* eslint-disable max-len */
import React from 'react'
import ImageAsset from '../ImageAsset/ImageAsset'

const ProductImageThumbnail = ({ template }) => {
  const { design, backgroundImage, graphicImage, backBackgroundImage, backGraphicImage } = template

  return (
    <>
      <div className='card-img'>
        {design === 'Front and Back' && (
          <div className='back-img' style={{ zIndex: 1 }}>
            <ImageAsset
              src={backBackgroundImage}
              className='card-img-top img-fluid full-size-img'
              alt='card img1'
            />
            <ImageAsset
              src={backGraphicImage}
              className='card-img-top img-fluid full-size-img'
              alt='card img1'
            />
          </div>
        )}
        <div className={design !== 'Front and Back' ? '' : 'front-img'} style={{ zIndex: 2 }}>
          <ImageAsset
            src={backgroundImage}
            className='card-img-top img-fluid full-size-img'
            alt='card img1'
          />
          <ImageAsset
            src={graphicImage}
            className='card-img-top img-fluid full-size-img'
            alt='card img1'
          />
        </div>
      </div>
    </>
  )
}

export default ProductImageThumbnail
