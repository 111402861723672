export const RoleMap = {
  CP: {
    value: 'CP',
    title: 'Cantact Persion',
  },
  SP: {
    value: 'SP',
    title: 'Sales Persion',
  },
  PA: {
    value: 'PA',
    title: 'Parent Admin',
  },
  MP: {
    value: 'MP',
    title: 'Marketing Person',
  },
  SA: {
    value: 'SA',
    title: 'Super Admin',
  },
}

export const AuthStepMap = {
  EMAIL_CHECK: 'EMAIL_CHECK',
  NEW_USER: 'NEW_USER',
  VERIFIED: 'VERIFIED',
}

export const ProductTypeMap = { OPEN_ITEM: 'OPEN_ITEM', CART_ITEM: 'CART_ITEM' }
export const CartStatusSMap = { OPEN: 'OPEN', CHECKOUT: 'CHECKOUT' }

export const twentyFourHoursInMs = 24 * 60 * 60 * 1000

export const DOZEN = 12

export const PRICE_ROWS_MAX_QUANTITY = 1200
export const PRICE_ROWS_INITIAL_DOZENS = 3

export const MIN_QTY_FOR_ADDITIONAL_SHIPPING_ADDRESS = 36

export const ACCOUNT_TABS = {
  accountDetail: 'account-detail',
  manageBuyers: 'manage-buyers',
  shippingAddresses: 'shipping-addresses',
  orderHistory: 'order-history',
  orderDetail: 'order-detail',
  creditCards: 'credit-cards',
  reorderProducts: 'reorder-products',
  myLocations: 'my-locations',
  salesAndUseForm: 'sales-and-form',
}

export const LOCAL_STORAGE_KEYS = {
  LOCATION_ID: 'LOCATION_ID',
}

export const SESSION_STORAGE_KEYS = {
  PARENT_ID: 'PARENT_ID',
  CHILD_ID: 'CHILD_ID',
  COLORS: {
    BODY: 'body',
    SHADE: 'shade',
    FRONT_GRAPHIC_DK: 'front_graphic_dk',
    FRONT_GRAPHIC_LT: 'front_graphic_lt',
    FRONT_GRAPHIC_NA: 'front_graphic_NA',
    FRONT_GRAPHIC2_DK: 'front_graphic2_dk',
    FRONT_GRAPHIC2_LT: 'front_graphic2_lt',
    FRONT_GRAPHIC2_NA: 'front_graphic2_NA',
    BACK_GRAPHIC_DK: 'back_graphic_dk',
    BACK_GRAPHIC_LT: 'back_graphic_lt',
    BACK_GRAPHIC_NA: 'back_graphic_NA',
    BACK_GRAPHIC2_DK: 'back_graphic2_dk',
    BACK_GRAPHIC2_LT: 'back_graphic2_lt',
    BACK_GRAPHIC2_NA: 'back_graphic2_NA',
  },
  LOGOS: {
    FRONT_DK: 'front_dk',
    FRONT_LT: 'front_lt',
    FRONT_NA: 'front_NA',
    BACK_DK: 'back_dk',
    BACK_LT: 'back_lt',
    BACK_NA: 'back_NA',
  },
  LOGO_FRONT_BASE_NAME: 'front_baseName',
  LOGO_BACK_BASE_NAME: 'back_baseName',
}
