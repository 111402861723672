import client from '../utils/axios'

export const getCart = (parentId) => {
  return client.get('/api/carts/' + parentId)
}

export const addToCart = ({ parentId, payload }) => {
  console.log({ parentId, payload })
  return client.post('/api/carts/' + parentId, payload)
}

export const removeFromCart = (cartItemId) => {
  return client.delete(`/api/carts/${cartItemId}`)
}
