import create from 'zustand'
import { getCustomers } from '../services/accountService'

const useCustomerStore = create((set) => ({
  customers: [],
  customerMap: {},
  orderPermissionForMarketingPerson: false,
  fetch: async (parentId) => {
    const customers = await getCustomers({ parentId })
    const customerMap = customers.reduce((a, c) => {
      a[c.id] = c
      return a
    }, {})
    const orderPermissionForMarketingPerson = customers.
      some(c => c.orderPermissionForMarketingPerson)
    set({ customers, customerMap, orderPermissionForMarketingPerson })
  },
}))

export default useCustomerStore
